import { Article } from 'entity'
import Link from 'next/link'
import React from 'react'
import useSWR from 'swr'
import NewsItem from '../NewsItem'

interface Props {
  categoryPage?: boolean
}

const FeaturedNewsSection = ({ categoryPage = false }: Props) => {
  const { data } = useSWR('/article/featured', {
    revalidateOnMount: true,
    initialData: {
      articles: [] as Article[],
      topArticle: null as Article,
    },
  })
  return (
    <section id="featured-news" className="container m-auto px-4 md:px-0">
      {categoryPage === true && (
        <h1 className="font-semibold text-xl md:text-3xl mb-4 md:mb-8">
          Berita Unggulan
        </h1>
      )}
      <div className="grid grid-cols-12 md:gap-16 md:divide-x divide-gray-300 ">
        <div className="col-span-12 md:col-span-6 mb-10 md:mb-0">
          {categoryPage === false && (
            <h1 className="font-semibold text-xl md:text-3xl mb-4 md:mb-8">
              Berita Unggulan
            </h1>
          )}
          <div>
            <NewsItem data={data.topArticle} skeleton={!data.topArticle} />
          </div>
        </div>
        <aside className="col-span-12 md:col-span-6 md:pl-16">
          {categoryPage === false && (
            <div className="flex">
              <h1 className="font-semibold text-2xl md:text-3xl mb-4 md:mb-8 flex-1">
                Berita Terbaru
              </h1>
              <Link href="/kategori/berita">
                <a className="font-semibold text-blue-100 mt-3 hover:underline">
                  Lihat lebih banyak
                </a>
              </Link>
            </div>
          )}
          <div>
            {data.articles.length === 0 &&
              [...new Array(4)].map((_, index) => (
                <div className="mb-6" key={index}>
                  <NewsItem type="sided" data={null} skeleton />
                </div>
              ))}
            {data.articles.map((item, index) => (
              <div className="mb-6" key={index}>
                <NewsItem type="sided" data={item} />
              </div>
            ))}
          </div>
        </aside>
      </div>
    </section>
  )
}

export default FeaturedNewsSection
