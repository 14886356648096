import {
  faBullhorn,
  faEnvelopeOpenText,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import Link from 'next/link'
import React from 'react'
import useSWR from 'swr'
import Skeleton from '../Skeleton'

interface Props {}

const AnnouncementInvitationSection = (props: Props) => {
  const { data: pengumuman } = useSWR('/article/pengumuman?perPage=5', {
    revalidateOnMount: true,
    initialData: { data: [] },
  })
  const { data: undangan } = useSWR('/article/undangan?perPage=5', {
    revalidateOnMount: true,
    initialData: { data: [] },
  })

  const SkeletonList = [...new Array(5)].map((_, index) => (
    <li className="flex pb-8 relative" key={index}>
      <Skeleton className="w-10 h-10 rounded-full" />
      <div className="flex-1 ml-4">
        <Skeleton className="h-6 mb-4 rounded" />
        <Skeleton className="h-3 rounded w-4/12" />
      </div>
    </li>
  ))

  const ListItem = (item, index) => {
    return (
      <li className="flex pb-8 relative" key={index}>
        <div className="count bg-gray-300 w-10 h-10 font-semibold flex justify-center items-center text-center rounded-full">
          {index + 1}
        </div>
        <div className="flex-1 ml-4">
          <Link href={`/konten/${item.id}/${item.slug}`}>
            <a className="stretched-link block transition-all duration-200 ease-in-out hover:underline">
              <h4 className="font-semibold mb-2 text-lg">{item.title}</h4>
            </a>
          </Link>
          <time
            className="opacity-75"
            dateTime={moment(item.createdAt).toISOString()}
          >
            {moment(item.createdAt).format('dddd, DD MMMM YYYY')}
          </time>
        </div>
      </li>
    )
  }

  return (
    <section
      id="announcement-and-invitation"
      className="bg-grey-100 pb-2 md:pb-20"
    >
      <div className="container m-auto px-4 py-8 md:py-20 md:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
          <div className="rounded-md shadow-md overflow-hidden bg-white">
            <div className="bg-indigo-100 text-white p-4 md:p-8">
              <span className="text-4xl mb-2">
                <FontAwesomeIcon icon={faBullhorn} />
              </span>
              <div className="flex items-center">
                <h2 className="text-2xl md:text-3xl font-semibold flex-1">
                  Pengumuman
                </h2>
                <Link href="/kategori/pengumuman">
                  <a className="font-semibold text-white text-sm md:text-base hover:underline">
                    Lihat lebih banyak
                  </a>
                </Link>
              </div>
            </div>
            <div className="p-4 md:p-8">
              <ul>
                {pengumuman.data.length === 0 ? SkeletonList : null}
                {pengumuman.data.map(ListItem)}
              </ul>
            </div>
          </div>
          <div className="rounded-md shadow-md overflow-hidden bg-white">
            <div className="bg-orange-100 text-white p-4 md:p-8">
              <span className="text-4xl mb-2">
                <FontAwesomeIcon icon={faEnvelopeOpenText} />
              </span>
              <div className="flex items-center">
                <h2 className="text-2xl md:text-3xl font-semibold flex-1">
                  Undangan
                </h2>
                <Link href="/kategori/undangan">
                  <a className="font-semibold text-white text-sm md:text-base hover:underline">
                    Lihat lebih banyak
                  </a>
                </Link>
              </div>
            </div>
            <div className="p-4 md:p-8">
              <ul>
                {undangan.data.length === 0 ? SkeletonList : null}
                {undangan.data.map(ListItem)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AnnouncementInvitationSection
