import clsx from 'clsx'
import { Article } from 'entity'
import moment from 'moment'
import Link from 'next/link'
import React, { Fragment } from 'react'
import getMediaURL from 'util/getMedia'
import Skeleton from './Skeleton'

interface Props {
  type?: 'bottom' | 'sided'
  data?: Article
  skeleton?: boolean
}

const BookItem = ({ type = 'sided', data = null, skeleton = false }: Props) => {
  if (data === null) return <Fragment />

  return (
    <div className="grid grid-cols-3 gap-4 relative">
      <div
        className={clsx({
          'col-span-3': type === 'bottom',
          'col-span-3 md:col-span-1': type === 'sided',
        })}
      >
        <div
          className={clsx({
            'w-4/6 mb-4': type === 'bottom',
            'w-4/6 md:w-full': type === 'sided',
          })}
        >
          {skeleton ? (
            <Skeleton style={{ paddingBottom: '140%' }} className="rounded" />
          ) : (
            <div
              className={clsx(
                'bg-gray-300 shadow-lg bg-cover bg-center placeholder relative'
              )}
              style={{
                paddingBottom: '140%',
                backgroundImage: `url(${require('images/thumbnails.svg')})`,
              }}
            >
              <figure
                style={{
                  backgroundImage: data.featuredImgSrc
                    ? `url(${getMediaURL(
                        'images',
                        'covers',
                        data.featuredImgSrc + '?thumbnail=default'
                      )})`
                    : null,
                }}
                className={clsx(
                  'shadow-lg bg-center bg-cover absolute w-full h-full',
                  {}
                )}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={clsx({
          'col-span-3 md:col-span-2': type === 'sided',
          'col-span-3': type === 'bottom',
        })}
      >
        {skeleton ? (
          <Fragment>
            <Skeleton className={`h-4 rounded mb-2`} />

            <Skeleton className={'inline-block w-3/12 h-3 rounded'} />
            <div className={'inline-block w-9/12 pl-3'}>
              <Skeleton className={'h-3 rounded'} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Link href={`/konten/${data.id}/${data.slug}`}>
              <a className="stretched-link hover:text-blue-100">
                <h3 className="font-semibold mb-2 text-base md:text-lg">
                  {data.title}
                </h3>
              </a>
            </Link>
            <time
              className="opacity-75"
              dateTime={moment(data.createdAt).toISOString()}
            >
              {moment(data.createdAt).format('dddd, DD MMMM YYYY')}
            </time>{' '}
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default BookItem
