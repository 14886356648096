import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useModal } from 'components/useModal'
import { Form, Formik } from 'formik'
import { Submit } from 'formstrap'
import React, { Fragment, useState } from 'react'
import { Input } from 'reactstrap'
import useSWR from 'swr'
import { Api } from 'util/fetcher'
import Skeleton from '../Skeleton'
import VoteResultChart from '../VoteResultChart'

interface Props {}

const SurveySection = (props: Props) => {
  const { ModalContainer, showAlert } = useModal()
  const { data, error } = useSWR('/survey', {
    revalidateOnMount: true,
    initialData: null,
  })

  const [showResult, setShowResult] = useState(false)
  const [surveyResult, setSurveyResult] = useState([])

  const onSubmitSurvey = async (values) => {
    try {
      const { data } = await Api().post('/survey', values)
      setSurveyResult(data.result)
      setShowResult(true)
    } catch (e) {
      showAlert({ content: e.message || 'Terjadi kesalahan pada koneksi' })
    }
  }

  return (
    <section
      id="survey"
      className="container m-auto mb-10 md:mb-20 px-4 md:px-0"
    >
      <div className="grid grid-cols-12 md:gap-8 border-b border-t py-10 border-gray-300">
        <div className="col-span-12 md:col-span-4">
          <h2 className="font-semibold text-2xl md:text-3xl mb-4 md:mb-8 flex-1">
            Survei Singkat
          </h2>
        </div>
        <div className="col-span-12 md:col-span-8">
          {showResult === true ? (
            <VoteResultChart data={surveyResult} />
          ) : (
            <Fragment>
              {data !== null ? (
                <Fragment>
                  <h3 className="font-semibold text-base md:text-2xl mb-8 md:w-8/12">
                    {data.question}
                  </h3>
                  <div>
                    <Formik
                      initialValues={{ answer: '1' }}
                      onSubmit={onSubmitSurvey}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          <div className="flex flex-col md:flex-row">
                            <label className="inline-flex items-center mr-4 mb-4 md:mb-0">
                              <Input
                                name="answer"
                                className="form-radio text-blue-100"
                                value="1"
                                checked={values.answer === '1'}
                                onChange={() => setFieldValue('answer', '1')}
                                type="radio"
                                id="answer-1"
                              />
                              <span className="ml-2">{data.option1}</span>
                            </label>
                            <label className="inline-flex items-center mr-4 mb-4 md:mb-0">
                              <Input
                                name="answer"
                                className="form-radio text-blue-100"
                                value="2"
                                checked={values.answer === '2'}
                                onChange={() => setFieldValue('answer', '2')}
                                type="radio"
                                id="answer-2"
                              />
                              <span className="ml-2">{data.option2}</span>
                            </label>
                            <label className="inline-flex items-center mr-4 mb-4 md:mb-0">
                              <Input
                                name="answer"
                                className="form-radio text-blue-100"
                                value="3"
                                checked={values.answer === '3'}
                                type="radio"
                                onChange={() => setFieldValue('answer', '3')}
                                id="answer-3"
                              />
                              <span className="ml-2">{data.option3}</span>
                            </label>
                            <label className="inline-flex items-center mr-4 mb-4 md:mb-0">
                              <Input
                                name="answer"
                                className="form-radio text-blue-100"
                                checked={values.answer === '4'}
                                value="4"
                                type="radio"
                                onChange={() => setFieldValue('answer', '4')}
                                id="answer-4"
                              />
                              <span className="ml-2">{data.option4}</span>
                            </label>
                          </div>

                          <div className="mt-0 md:mt-5 flex flex-col md:flex-row">
                            {values.answer === '4' && (
                              <div
                                style={{ maxWidth: 500 }}
                                className="flex-1 mb-4 md:mr-4"
                              >
                                <Input
                                  type="text"
                                  name="reason"
                                  value={values.reason}
                                  onChange={(e) =>
                                    setFieldValue('reason', e.target.value)
                                  }
                                  maxLength={255}
                                  placeholder="Masukkan alasan anda"
                                  className="w-full"
                                />
                              </div>
                            )}
                            <div className="ml-0">
                              <Submit color="primary">
                                Kirim Jawaban{' '}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </Submit>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <Skeleton className={'w-8/12 h-6 rounded'} />
                  <div className="flex mt-8 gap-8 mb-8">
                    <div className="w-6/12 md:w-full md:flex-1">
                      <Skeleton className={'h-4 rounded'} />
                    </div>
                    <div className="w-6/12 md:w-full md:flex-1">
                      <Skeleton className={'h-4 rounded'} />
                    </div>
                    <div className="w-6/12 md:w-full md:flex-1">
                      <Skeleton className={'h-4 rounded'} />
                    </div>
                    <div className="w-6/12 md:w-full md:flex-1">
                      <Skeleton className={'h-4 rounded'} />
                    </div>
                  </div>
                  <div className="flex justify-end gap-8">
                    <div className="flex-1">
                      <Skeleton className={'h-10 rounded'} />
                    </div>
                    <div className="w-24">
                      <Skeleton className={'h-10 rounded'} />
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </div>
      {ModalContainer({})}
    </section>
  )
}

export default SurveySection
