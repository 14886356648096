import clsx from 'clsx'
import { Article } from 'entity'
import moment from 'moment'
import Link from 'next/link'
import React, { Fragment } from 'react'
import getMediaURL from 'util/getMedia'
import Skeleton from './Skeleton'

interface Props {
  withDate?: boolean
  data?: Article
  skeleton?: boolean
}

const InfografisItem = ({ withDate, data = null, skeleton = false }: Props) => {
  if (data === null) return <Fragment />
  return (
    <div className="relative">
      {skeleton ? (
        <Fragment>
          <Skeleton style={{ paddingBottom: '100%' }} className={'rounded'} />
          <Skeleton className={`h-4 rounded mt-3 mb-3`} />
          <Skeleton className={'inline-block w-3/12 h-3 rounded'} />
          <div className={'inline-block w-9/12 pl-3'}>
            <Skeleton className={'h-3 rounded'} />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div
            className={clsx(
              'bg-gray-300 shadow-lg bg-cover bg-center placeholder relative'
            )}
            style={{
              paddingBottom: '100%',
              backgroundImage: `url(${require('images/thumbnails.svg')})`,
            }}
          >
            <figure
              className="rounded-md bg-center bg-cover absolute w-full h-full"
              style={{
                backgroundImage: `url(${getMediaURL(
                  'images',
                  'covers',
                  data.featuredImgSrc
                )})`,
              }}
            />
          </div>
          <Link href={`/konten/${data.id}/${data.slug}`}>
            <a className="stretched-link">
              <h3 className="font-semibold text-xl mt-3">{data.title}</h3>
            </a>
          </Link>
          {withDate && (
            <time
              className="opacity-75 mt-2"
              dateTime={moment(data.createdAt).toISOString()}
            >
              {moment(data.createdAt).format('dddd, DD MMMM YYYY')}
            </time>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default InfografisItem
