import { Gallery } from 'entity'
import Link from 'next/link'
import React from 'react'
import ReactSlick from 'react-slick'
import useSWR from 'swr'
import GalleryItem from '../GalleryItem'
interface Props {}

const GallerySection = (props: Props) => {
  const {
    data: { data },
  } = useSWR('/galeri', {
    revalidateOnMount: true,
    initialData: {
      data: [] as Gallery[],
    },
  })

  return (
    <section id="galleries" className="container m-auto mb-10 md:pb-20">
      <div className="flex items-center mb-4 md:mb-8 px-4 md:px-0">
        <h1 className="font-semibold text-2xl md:text-3xl flex-1">
          Galeri Foto
        </h1>
        <Link href="/galeri">
          <a className="font-semibold text-blue-100 hover:underline">
            Lihat lebih banyak
          </a>
        </Link>
      </div>
      <ReactSlick
        slidesToShow={3}
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: '45px',
            },
          },
        ]}
        arrows={false}
      >
        {data.length === 0 &&
          [...new Array(3)].map((_, index) => (
            <div className="px-2 md:px-4" key={index}>
              <GalleryItem skeleton />
            </div>
          ))}
        {data.map((item, index) => (
          <div className="px-2 md:px-4" key={index}>
            <GalleryItem data={item} />
          </div>
        ))}
      </ReactSlick>
    </section>
  )
}

export default GallerySection
