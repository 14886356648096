import clsx from 'clsx'
import { Article } from 'entity'
import getYouTubeID from 'get-youtube-id'
import moment from 'moment'
import Link from 'next/link'
import React, { useMemo, useState } from 'react'
import useSWR from 'swr'
import formatNumber from 'util/formatNumber'
import Skeleton from '../Skeleton'

interface Props {}

const VideoSection = (props: Props) => {
  const {
    data: { data },
  } = useSWR('/article/featured?type=video', {
    revalidateOnMount: true,
    initialData: {
      data: [] as Article[],
    },
  })

  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectedVideo = useMemo(() => {
    if (data.length > 0) {
      return data[selectedIndex]
    }
    return null
  }, [selectedIndex, data])

  const youtubeId = useMemo(() => {
    if (selectedVideo !== null) {
      return getYouTubeID(selectedVideo.optVideoExternalLink)
    }
    return null
  }, [selectedVideo])

  const selectVideo = (e, index) => {
    e.preventDefault()
    setSelectedIndex(index)
  }

  return (
    <section
      id="videos"
      className="bg-blue-300 text-white mb-10 md:mb-20 px-4 md:px-0"
    >
      <div className="container m-auto py-6 md:py-20">
        <div className="flex items-center">
          <h1 className="font-semibold text-2xl md:text-3xl mb-0 md:mb-8 flex-1">
            Video Pilihan
          </h1>
          <div className="block md:hidden">
            <Link href="/kategori/video">
              <a className="font-semibold text-sm md:text-base hover:underline">
                Lihat lebih banyak
              </a>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-12 md:gap-8">
          <div className="col-span-12 md:col-span-5">
            <ul className="md:pr-20">
              {data.length === 0 &&
                [...new Array(4)].map((_, index) => (
                  <li
                    className={
                      'flex border-b border-gray-500 border-opacity-25 py-6 relative'
                    }
                    key={index}
                  >
                    <Skeleton className="w-10 h-10 rounded-full" />
                    <div className="flex-1 ml-4">
                      <Skeleton className="h-6 mb-4 rounded" />
                      <Skeleton className="h-3 rounded w-4/12" />
                    </div>
                  </li>
                ))}
              {data.map((item, index) => (
                <li
                  className={
                    'flex border-b border-gray-500 border-opacity-25 py-6 relative'
                  }
                  key={index}
                >
                  <div
                    className={clsx(
                      'count bg-blue-100 w-10 h-10 font-semibold flex justify-center items-center text-center rounded-full hover:opacity-100 transition-all duration-300 ease-in-out',
                      {
                        'opacity-100': selectedIndex === index,
                        'opacity-75': selectedIndex !== index,
                      }
                    )}
                  >
                    {index + 1}
                  </div>
                  <div className="flex-1 ml-4">
                    <a
                      href={`/konten/${item.id}/${item.slug}`}
                      onClick={(e) => selectVideo(e, index)}
                      className={clsx(
                        'stretched-link hover:opacity-100 block transition-all duration-300 ease-in-out',
                        {
                          'opacity-100': selectedIndex === index,
                          'opacity-50': selectedIndex !== index,
                        }
                      )}
                    >
                      <h4 className="font-semibold mb-2 text-lg">
                        {item.title}
                      </h4>
                    </a>
                    <p className="opacity-75">
                      {moment(item.publishedAt).format('DD MMM YYYY')} •{' '}
                      {formatNumber(item.visitCountCache)} tayangan
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-8 hidden md:block">
              <Link href="/kategori/video">
                <a className="font-semibold">Lihat lebih banyak</a>
              </Link>
            </div>
          </div>
          <div className="col-span-12 md:col-span-7">
            <div
              className="bg-gray-900 bg-center bg-no-repeat bg-cover relative"
              style={{
                paddingBottom: '56.25%',
              }}
            >
              {data.length === 0 ? (
                <Skeleton className="rounded absolute w-full h-full" />
              ) : (
                <iframe
                  width="100%"
                  height="100%"
                  className="absolute w-full h-full"
                  src={`https://www.youtube.com/embed/${youtubeId}`}
                />
              )}
              {/* <div className="absolute w-full h-full  flex justify-center items-center cursor-pointer">
                <div className="absolute h-full w-full bg-black opacity-25" />
                <div className="text-white text-6xl relative z-10">
                  <FontAwesomeIcon icon={faYoutube} />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoSection
