import { Article } from 'entity'
import Link from 'next/link'
import React from 'react'
import useSWR from 'swr'
import NewsItem from '../NewsItem'

interface Props {}

const RevitalisasiSection = (props: Props) => {
  const { data, isValidating } = useSWR(
    '/article/featured?type=smk-pusat-keunggulan',
    {
      revalidateOnMount: true,
      initialData: {
        articles: [] as Article[],
      },
    }
  )

  return (
    <section
      id="revitalisasi-vokasi"
      className="container m-auto mb-10 md:mb-20 px-4 md:px-0"
    >
      <div className="flex items-center mb-4 md:mb-8">
        <h1 className="font-semibold text-2xl md:text-3xl flex-1">
          SMK Pusat Keunggulan
        </h1>
        <Link href="/kategori/smk-pusat-keunggulan">
          <a className="font-semibold text-blue-100 text-sm md:text-base hover:underline">
            Lihat lebih banyak
          </a>
        </Link>
      </div>
      <div className="grid gap-4 md:gap-8 grid-cols-1 md:grid-cols-4">
        {data.articles.length === 0
          ? [...new Array(4)].map((_, index) => (
              <div className="col-span-1" key={index}>
                <NewsItem mobile="sided" data={null} skeleton />
              </div>
            ))
          : null}
        {data.articles.map((item, index) => (
          <div key={index} className="col-span-1">
            <NewsItem data={item} mobile="sided" />
          </div>
        ))}
      </div>
    </section>
  )
}

export default RevitalisasiSection
