import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import ReactSlick from 'react-slick'
import useSWR from 'swr'
import getMediaURL from 'util/getMedia'
import Skeleton from '../Skeleton'

const PortalSlide = () => {
  const { data } = useSWR('/portals', {
    initialData: {
      portals: [...new Array(8)].map(() => ({
        skeleton: true,
        link: null,
        icon: null,
        text: null,
      })),
    },
    revalidateOnMount: true,
  })

  const NextArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <div
        className={clsx(
          // className,
          'absolute right-0 top-0 z-10 flex justify-center items-center'
        )}
        style={{ ...style, right: -50, display: 'flex', bottom: '45%' }}
        onClick={onClick}
      >
        <div className="cursor-pointer border border-blue-100 rounded-full w-10 h-10 text-center pt-2 transition-transform ease-in-out duration-150 transform hover:translate-x-2">
          <FontAwesomeIcon icon={faArrowRight} className="text-blue-100" />
        </div>
      </div>
    )
  }

  const PrevArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <div
        className={clsx(
          // className,
          'absolute left-0 top-0 z-10 flex justify-center items-center'
        )}
        style={{ ...style, left: -50, display: 'flex', bottom: '45%' }}
        onClick={onClick}
      >
        <div className="cursor-pointer border border-blue-100 rounded-full w-10 h-10 text-center pt-2 transition-transform ease-in-out duration-150 transform hover:-translate-x-2">
          <FontAwesomeIcon icon={faArrowLeft} className="text-blue-100" />
        </div>
      </div>
    )
  }

  return (
    <section id="slide-menu" className="mb-10 md:mb-32 m-auto container">
      <ReactSlick
        slidesToShow={8}
        infinite={data.portals.length > 8}
        responsive={[
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              centerMode: true,
              centerPadding: '30px',
              arrows: false,
            },
          },
        ]}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
      >
        {data.portals.map((item, index) => {
          return (
            <div key={index} className="px-2 md:px-4 pt-3">
              {item.skeleton ? (
                <div>
                  <div className="px-4">
                    <Skeleton
                      style={{ paddingBottom: '100%' }}
                      className="rounded"
                    />
                  </div>
                  <Skeleton className="h-4 mt-6 rounded" />
                </div>
              ) : (
                <a
                  href={item.link}
                  className="block transition-transform ease-in-out duration-200 transform hover:-translate-y-3"
                >
                  <div className="icon flex justify-center mb-4">
                    <div className="rounded-md w-20 h-20">
                      <img
                        src={getMediaURL(
                          'images',
                          'portal',
                          'icons',
                          item.icon
                        )}
                        alt={item.text}
                        className="max-w-full"
                      />
                    </div>
                  </div>
                  <div className="font-semibold text-sm md:text-base text-center">
                    <p>{item.text}</p>
                  </div>
                </a>
              )}
            </div>
          )
        })}
      </ReactSlick>
    </section>
  )
}

export default PortalSlide
