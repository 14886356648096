import { Slider } from 'entity'
import React, { Fragment, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import ReactSlick from 'react-slick'
import useSWR from 'swr'
import { theme } from 'tailwind.config'
import getMediaURL from 'util/getMedia'
import Skeleton from '../Skeleton'

interface Props {}

export const PrimarySlider = (props: Props) => {
  const { data } = useSWR('/slider', {
    revalidateOnMount: true,
    initialData: { slider: [] as Slider[] },
  })
  const primarySlide = useRef(null)
  const secondarySlide = useRef(null)
  const slides = [...new Array(8)].map((item) => ({
    image: '',
  }))

  const isMobile = useMediaQuery({
    query: `(max-device-width: ${theme.screens.md})`,
  })

  return (
    <section id="primary-slide" className="mb-20">
      <div className="slider bg-blue-300 text-white">
        <div className="slider-container m-auto">
          <div className="grid grid-cols-1 md:grid-cols-2">
            {data.slider.length > 0 ? (
              <Fragment>
                <div className="slider-left relative bg-blue-200">
                  <div className="bg-gray-300 h-full">
                    <ReactSlick
                      arrows={false}
                      ref={primarySlide}
                      swipe={false}
                      responsive={[
                        {
                          breakpoint: theme.screens.md,
                          settings: {
                            swipe: true,
                          },
                        },
                      ]}
                      beforeChange={(curr, next) => {
                        if (isMobile) {
                          secondarySlide.current.slickGoTo(next)
                        }
                      }}
                    >
                      {data.slider.map((item, index) => (
                        <div key={index}>
                          <div
                            className="bg-center bg-cover h-full -mb-2"
                            style={{
                              paddingBottom: '75%',
                              backgroundImage: `url(${getMediaURL(
                                'images',
                                'sliders',
                                item.image
                              )})`,
                            }}
                          />
                        </div>
                      ))}
                    </ReactSlick>
                  </div>
                </div>
                <div className="slider-right">
                  <ReactSlick
                    arrows={false}
                    ref={secondarySlide}
                    swipeToSlide={false}
                    vertical
                    autoplay
                    autoplaySpeed={7000}
                    swipe={false}
                    responsive={[
                      {
                        breakpoint: theme.screens.md,
                        settings: {
                          vertical: false,
                        },
                      },
                    ]}
                    beforeChange={(curr, next) => {
                      primarySlide.current.slickGoTo(next)
                    }}
                    dots
                    dotsClass="primary-dots absolute bottom-0 left-0 ml-4 mb-6 md:ml-24 md:mb-12"
                  >
                    {data.slider.map((item, index) => (
                      <div key={index} className="relative">
                        <div style={{ paddingBottom: '75%' }} className="-mb-2">
                          <div className="absolute w-full h-full flex flex-col justify-center p-4 pb-10 md:px-24 md:pb-12">
                            <h2 className="text-xl md:text-3xl font-semibold mb-4 md:w-10/12">
                              {item.title}
                            </h2>
                            {item.description && (
                              <p className="mb-8 md:w-10/12">
                                {item.description}
                              </p>
                            )}
                            {item.url && (
                              <div>
                                <a
                                  href={item.url}
                                  className="bg-blue-100 px-4 py-2 inline-block rounded-md transform hover:scale-105 transition-transform duration-100"
                                >
                                  Selengkapnya
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </ReactSlick>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div
                  className="slider-left bg-black opacity-25"
                  style={{ paddingBottom: '75%' }}
                />
                <div className="slider-right relative">
                  <div style={{ paddingBottom: '75%' }} className="-mb-2">
                    <div className="absolute w-full h-full flex flex-col justify-center p-4 pb-10 md:px-24 md:pb-12">
                      <Skeleton className={'w-7/12 h-8 rounded'} />
                      <Skeleton className={'w-5/12 h-3 mt-6 rounded'} />
                      <Skeleton className={'w-24 h-10 mt-16 rounded'} />
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <style global>{`
        .primary-dots {
          position: absolute;
        }
        .primary-dots li {
          display: inline-block;
        }
        .primary-dots li button {
          font-size: 0;
          opacity: 0.5;
          padding: .5rem;
        }
        .primary-dots li button:after {
          content: '';
          display: block;
          background: #fff;
          width: 24px;
          height: 6px;
          border-radius: 5px;
        }
        .primary-dots li.slick-active button {
          opacity: 1;
        }
        @media (max-width: ${theme.screens.md}) {
        .primary-dots li {
          padding: 0 6px;
        }
      `}</style>
        <style jsx>{`
          .slider-container {
            max-width: 1440px;
          }
        `}</style>
      </div>
    </section>
  )
}
