import React, { useMemo } from 'react'
import { Pie } from 'react-chartjs-2'
require('chart.piecelabel.js')

interface VoteData {
  label: string
  total: number
}

interface Props {
  data: VoteData[]
}

const colormaps = ['#009688', '#2196F3', '#F57C00', '#B71C1C']

const VoteResultChart = ({ data }: Props) => {
  const sumAll = useMemo(
    () => data.map((item) => item.total).reduce((i, j) => i + j),
    [data]
  )

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-5/12 pb-8 pl-8 pr-8 md:pl-0 md:pr-12">
        <Pie
          width={50}
          height={50}
          data={{
            labels: data.map((item) => item.label),
            datasets: [
              {
                borderWidth: 3,
                backgroundColor: colormaps,
                data: data.map((item) => item.total),
              },
            ],
          }}
          options={{
            legend: {
              display: false,
            },
            pieceLabel: {
              render: 'percentage',
              fontColor: '#fff',
            },
          }}
        />
      </div>
      <div className="w-full md:w-7/12">
        <img src={require('images/success-icon.svg')} alt="success" />
        <h2 className="text-xl md:text-2xl font-semibold mt-4 mb-4">
          Terima kasih atas partisipasi Anda
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {data.map((item, index) => (
            <div key={index} className="col-span-1 flex">
              <div>
                <div
                  className="w-4 h-4 rounded-full mt-1 mr-2"
                  style={{ backgroundColor: colormaps[index] }}
                />
              </div>
              <div className="flex-1">
                <h3 className="mb-1">{item.label}</h3>
                <p className="opacity-75">
                  {item.total} Voters (
                  {((item.total / sumAll) * 100).toFixed(2)}
                  %)
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default VoteResultChart
