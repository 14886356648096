import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import socialMedia from 'vars/social-media'

interface Props {}

const MediaSocialSection = (props: Props) => {
  return (
    <section id="media-social" className="bg-grey-100 px-4 md:px-0">
      <div className="container m-auto border-t border-gray-400 py-8 md:py-10">
        <div className="grid md:grid-cols-2 md:gap-8">
          <div>
            <h2 className="mb-4 text-2xl md:text-3xl font-semibold">
              Media Sosial
            </h2>
            <p className="opacity-75 mb-5 md:w-8/12">
              Dapatkan informasi terbaru dengan mengikuti dan berlangganan akun
              media sosial Direktorat SMK.
            </p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <a
                href={socialMedia.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className="h-12 w-12 rounded-full mb-3 flex items-center justify-center text-white text-xl"
                  style={{ background: '#1674EA' }}
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </span>
                <span className="font-semibold block">Facebook</span>
                <span className="opacity-75">Direktorat SMK</span>
              </a>
            </div>

            <div>
              <a
                href={socialMedia.youtube}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className="h-12 w-12 rounded-full mb-3 flex items-center justify-center text-white text-xl"
                  style={{ background: '#E02016' }}
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </span>
                <span className="font-semibold block">Youtube</span>
                <span className="opacity-75">Direktorat SMK</span>
              </a>
            </div>

            <div>
              <a
                href={socialMedia.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className="h-12 w-12 rounded-full mb-3 flex items-center justify-center text-white text-xl"
                  style={{ background: '#1C9CEA' }}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </span>
                <span className="font-semibold block">Twitter</span>
                <span className="opacity-75">@direktoratsmk</span>
              </a>
            </div>

            <div>
              <a
                href={socialMedia.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className="h-12 w-12 rounded-full mb-3 flex items-center justify-center text-white text-xl"
                  style={{ background: '#D02873' }}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </span>
                <span className="font-semibold block">Instagram</span>
                <span className="opacity-75">@direktoratsmk</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MediaSocialSection
