import clsx from 'clsx'
import { Gallery } from 'entity'
import moment from 'moment'
import Link from 'next/link'
import React, { Fragment, useCallback, useMemo } from 'react'
import getMediaURL from 'util/getMedia'
import style from './GalleryItem.module.scss'
import Skeleton from './Skeleton'

interface Props {
  data?: Gallery
  skeleton?: boolean
}

const GalleryItem = ({ data: _data = null, skeleton = false }: Props) => {
  const data = useMemo(() => {
    if (_data === null && skeleton === true) {
      return { id: -1, galleries: [] } as Gallery
    }
    return _data
  }, [_data])

  if (data === null) return <Fragment />

  const getImage = useCallback(
    (index) => {
      if (!data.galleries) {
        return null
      }

      return data.galleries[index]
        ? getMediaURL('images', 'galleries', data.galleries[index].src)
        : null
    },
    [data.galleries]
  )

  return (
    <div
      id={`gallery-${data.id}`}
      className={clsx(
        { [style['gallery-item-loaded']]: !skeleton },
        'relative'
      )}
    >
      <div
        className={clsx(style.thumbnails, 'bg-grey-100 p-2 md:p-4 rounded-md')}
      >
        <div className="grid grid-cols-3 gap-2 md:gap-3">
          {skeleton ? (
            <Fragment>
              {[...new Array(4)].map((_, index) => (
                <Skeleton
                  className={clsx('rounded', {
                    'col-span-3': index === 0,
                    'col-span-1': index > 0,
                  })}
                  style={{
                    paddingBottom: '55%',
                  }}
                />
              ))}
            </Fragment>
          ) : (
            <Fragment>
              {[...new Array(4)].map((_, index) => (
                <div
                  className={clsx(
                    'bg-gray-300 rounded bg-center bg-cover bg-no-repeat',
                    {
                      'col-span-3': index === 0,
                      'col-span-1': index > 0,
                    }
                  )}
                  style={{
                    paddingBottom: '55%',
                    backgroundImage: `url(${getImage(index)})`,
                  }}
                />
              ))}
            </Fragment>
          )}
        </div>
      </div>

      <div className="mt-4">
        {skeleton ? (
          <Fragment>
            <Skeleton className={`h-4 rounded mt-3 mb-3`} />
            <Skeleton className={'inline-block w-3/12 h-3 rounded'} />
            <div className={'inline-block w-9/12 pl-3'}>
              <Skeleton className={'h-3 rounded'} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Link href={`/galeri/${data.id}/${data.slug}`}>
              <a className="stretched-link hover:text-blue-100">
                <h5 className="text-base md:text-lg font-semibold">
                  {data.title}
                </h5>
              </a>
            </Link>
            <div className="mt-3">
              <time dateTime={moment(data.publishedAt).toISOString()}>
                {moment(data.publishedAt).format('dddd, DD MMMM YYYY')}
              </time>{' '}
              &middot; oleh{' '}
              <span>{[data.user.firstName, data.user.lastName].join(' ')}</span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default GalleryItem
