import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { Article } from 'entity'
import { ContentType } from 'entity/Article'
import Link from 'next/link'
import React, { useRef } from 'react'
import ReactSlick from 'react-slick'
import useSWR from 'swr'
import NewsItem from '../NewsItem'

interface Props {}

const InfografisSection = (props: Props) => {
  const { data } = useSWR('/infographic/featured', {
    revalidateOnMount: true,
    initialData: {
      infographics: [] as Article[],
    },
  })
  const slick = useRef(null)
  const NextArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <div
        className={clsx(className)}
        style={{ ...style, right: -50, display: 'flex', bottom: '45%' }}
        onClick={onClick}
      >
        <div className="cursor-pointer border border-blue-100 rounded-full w-10 h-10 text-center pt-2 transition-all ease-in-out duration-150 transform text-blue-100 hover:bg-blue-100 hover:text-white">
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    )
  }

  const PrevArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <div
        className={clsx(className)}
        style={{ ...style, left: -50, display: 'flex', bottom: '45%' }}
        onClick={onClick}
      >
        <div className="cursor-pointer border border-blue-100 rounded-full w-10 h-10 text-center pt-2 transition-all ease-in-out duration-150 transform text-blue-100 hover:bg-blue-100 hover:text-white">
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      </div>
    )
  }

  return (
    <section className="container m-auto mb-10 md:mb-20" id="infografis">
      <div className="grid grid-cols-4 md:gap-16">
        <div className="col-span-4 md:col-span-1 flex flex-col">
          <div className="flex-1 px-4 md:px-0">
            <h2 className="mb-4 text-3xl font-semibold">Infografis</h2>
            <p className="opacity-75 mb-5">
              Informasi dalam bentuk yang ringkas dan bernas
            </p>
            <Link href="/kategori/infografis">
              <a className="hidden md:inline-block font-semibold text-blue-100 hover:underline">
                Lihat lebih banyak
              </a>
            </Link>
          </div>
          <div className="hidden relative md:flex flex-row gap-4">
            <PrevArrow onClick={() => slick.current.slickPrev()} />
            <NextArrow onClick={() => slick.current.slickNext()} />
          </div>
        </div>
        <div className="col-span-4 md:col-span-3">
          <ReactSlick
            slidesToShow={3}
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  centerMode: true,
                  centerPadding: '45px',
                },
              },
            ]}
            arrows={false}
            ref={slick}
          >
            {data.infographics.length === 0 &&
              [...new Array(3)].map((_, index) => (
                <div key={index} className="p-4 pb-0">
                  <NewsItem
                    contentType={ContentType.Infographic}
                    data={null}
                    skeleton
                  />
                </div>
              ))}
            {data.infographics.map((item, index) => (
              <div key={index} className="p-4 pb-0">
                <NewsItem data={item} />
              </div>
            ))}
          </ReactSlick>
        </div>
      </div>
    </section>
  )
}

export default InfografisSection
