import BaseFrontend from 'components/frontend/BaseFrontend'
import AnnouncementInvitationSection from 'components/frontend/main/AnnouncementInvitationSection'
import FeaturedNewsSection from 'components/frontend/main/FeaturedNewsSection'
import GallerySection from 'components/frontend/main/GallerySection'
import InfografisSection from 'components/frontend/main/InfografisSection'
import MediaSocialSection from 'components/frontend/main/MediaSocialSection'
import PopularSection from 'components/frontend/main/PopularSection'
import PortalSlide from 'components/frontend/main/PortalSlide'
import { PrimarySlider } from 'components/frontend/main/PrimarySlider'
import PublicationSection from 'components/frontend/main/PublicationSection'
import RevitalisasiSection from 'components/frontend/main/RevitalisasiSection'
import SurveySection from 'components/frontend/main/SurveySection'
import VideoSection from 'components/frontend/main/VideoSection'
import { GetServerSideProps } from 'next'
import { NextSeo } from 'next-seo'
import React from 'react'
import fetchBaseFrontendProps from 'util/fetchBaseFrontendProps'
import nextMiddleware from 'util/nextMiddleware'

interface Props {
  menus: any
}

const IndexPage = ({ menus }: Props) => {
  return (
    <BaseFrontend menus={menus}>
      <NextSeo title="Beranda" />
      <div className="mb-10">
        <PrimarySlider />
        <PortalSlide />
        <FeaturedNewsSection />
        <RevitalisasiSection />
        <PublicationSection />
        <PopularSection />
        <InfografisSection />
        <AnnouncementInvitationSection />
        <MediaSocialSection />
        <VideoSection />
        <GallerySection />
        <SurveySection />
      </div>
    </BaseFrontend>
  )
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  try {
    await nextMiddleware(ctx)
    const baseFrontend = await fetchBaseFrontendProps(ctx)

    return {
      props: { ...baseFrontend },
    }
  } catch (e) {
    console.error(e)
    return { props: { status: 500 } }
  }
}

export default IndexPage
