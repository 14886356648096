import clsx from 'clsx'
import { Article } from 'entity'
import { ContentType } from 'entity/Article'
import getYouTubeID from 'get-youtube-id'
import moment from 'moment'
import Link from 'next/link'
import React, { Fragment, useMemo } from 'react'
import getMediaURL from 'util/getMedia'
import BookItem from './BookItem'
import InfografisItem from './InfografisItem'
import style from './NewsItem.module.scss'
import Skeleton from './Skeleton'

interface Props {
  type?: 'bottom' | 'sided'
  mobile?: 'default' | 'sided' | 'bottom'
  data?: Article
  icon?: React.ReactNode
  skeleton?: boolean
  contentType?: ContentType
}

const NewsItem = ({
  type = 'bottom',
  mobile = 'default',
  data: _data = null,
  icon,
  skeleton = false,
  contentType = ContentType.Content,
}: Props) => {
  const data = useMemo(() => {
    return _data === null ? ({ id: -1, type: contentType } as Article) : _data
  }, [_data])

  if (_data === null && skeleton === false) {
    return <Fragment />
  }

  if (!data.id && skeleton === false) {
    return <Fragment />
  }

  if (data.type === ContentType.Publication) {
    return <BookItem data={data} type={type} skeleton={skeleton} />
  }

  if (data.type === ContentType.Infographic) {
    return <InfografisItem data={data} withDate={true} skeleton={skeleton} />
  }

  const youtubeId = useMemo(() => {
    if (data.optVideoExternalLink) {
      return getYouTubeID(data.optVideoExternalLink)
    }
    return null
  }, [data])

  const Thumbnail = ({ className }) => {
    if (icon) {
      return <div className="text-2xl">{icon}</div>
    }

    if (skeleton) {
      return <Skeleton className={clsx('placeholder', className)} />
    }

    return (
      <Fragment>
        <div
          className={clsx(
            'bg-gray-300 bg-cover bg-center placeholder relative rounded',
            className
          )}
          style={{
            backgroundImage: `url(${require('images/thumbnails.svg')})`,
          }}
        >
          <figure
            className={clsx(
              'rounded-md bg-cover bg-center absolute w-full h-full'
            )}
            style={{
              backgroundImage:
                youtubeId !== null
                  ? `url(http://i3.ytimg.com/vi/${youtubeId}/hqdefault.jpg)`
                  : data.featuredImgSrc
                  ? `url(${getMediaURL(
                      'images',
                      'covers',
                      data.featuredImgSrc + '?thumbnail=default'
                    )})`
                  : null,
            }}
          />
        </div>
      </Fragment>
    )
  }

  const Detail = () => (
    <Fragment>
      {skeleton ? (
        <Skeleton className={`h-4 rounded`} />
      ) : (
        <Link href={`/konten/${data.id}/${data.slug}`}>
          <a className="stretched-link hover:text-blue-100">
            <h5 className="text-base md:text-lg font-semibold">{data.title}</h5>
          </a>
        </Link>
      )}
      <div className="mt-3">
        {skeleton ? (
          <Fragment>
            <Skeleton className={'inline-block w-3/12 h-3 rounded'} />
            <div className={'inline-block w-9/12 pl-3'}>
              <Skeleton className={'h-3 rounded'} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <time
              dateTime={moment(
                data.publishedAt || data.createdAt
              ).toISOString()}
            >
              {moment(data.publishedAt || data.createdAt).format(
                'dddd, DD MMMM YYYY'
              )}
            </time>{' '}
            &middot; oleh{' '}
            <span>
              {[data.published.firstName, data.published.lastName].join(' ')}
            </span>
          </Fragment>
        )}
      </div>
    </Fragment>
  )

  if (type === 'sided') {
    return (
      <article
        id={`article-${data.id}`}
        className={clsx(
          { [style['news-item-loaded']]: !skeleton },
          'sided flex relative gap-4'
        )}
      >
        <div className="detail w-8/12">
          <Detail />
        </div>
        <div className="thumbnail w-4/12">
          <Thumbnail
            className={clsx(style.thumbnails, style['thumbnails-sided'])}
          />
        </div>
      </article>
    )
  }

  if (type === 'bottom') {
    return (
      <article
        id={`article-${data.id}`}
        className={clsx(
          { [style['news-item-loaded']]: !skeleton },
          'bottom',
          { 'mobile-sided flex md:flex-col': mobile === 'sided' },
          { 'flex flex-col': mobile !== 'sided' },
          'relative'
        )}
      >
        <div
          className={clsx('thumbnail mb-4', {
            'order-last md:order-first md:w-full': mobile === 'sided',
            'w-4/12': mobile === 'sided' && !icon,
          })}
        >
          <Thumbnail
            className={clsx(style.thumbnails, {
              [style['thumbnails-sided']]: mobile === 'sided',
            })}
          />
        </div>
        <div
          className={clsx('detail', {
            'order-first md:order-last md:w-full pr-4 md:pr-0':
              mobile === 'sided',
            'w-8/12': mobile === 'sided' && !icon,
            'w-full': mobile === 'sided' && icon,
          })}
        >
          <Detail />
        </div>
      </article>
    )
  }
}

export default NewsItem
