import { Article } from 'entity'
import { ContentType } from 'entity/Article'
import Link from 'next/link'
import React from 'react'
import useSWR from 'swr'
import NewsItem from '../NewsItem'

interface Props {}

const PublicationSection = (props: Props) => {
  const { data } = useSWR('/publication/featured', {
    revalidateOnMount: true,
    initialData: {
      publications: [] as Article[],
    },
  })

  return (
    <section id="publications" className="bg-grey-100 pb-4 md:pb-20 md:mb-20">
      <div className="container m-auto py-8 px-4 md:py-20 md:px-0">
        <div className="flex items-center mb-8">
          <h1 className="font-semibold text-2xl md:text-3xl flex-1">
            Terbitan, Buku, dan Modul
          </h1>
          <Link href="/kategori/buku">
            <a className="font-semibold text-blue-100 hover:underline">
              Lihat lebih banyak
            </a>
          </Link>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-10">
          {data.publications.length === 0
            ? [...new Array(6)].map((_, index) => (
                <NewsItem
                  key={index}
                  data={null}
                  type="sided"
                  contentType={ContentType.Publication}
                  skeleton
                />
              ))
            : null}
          {data.publications.map((item, index) => (
            <NewsItem type="sided" key={index} data={item} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default PublicationSection
