import { faStars } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Article } from 'entity'
import moment from 'moment'
import Link from 'next/link'
import React from 'react'
import useSWR from 'swr'
import Skeleton from '../Skeleton'

interface Props {}

const PopularSection = (props: Props) => {
  const { data } = useSWR('/article/popular', {
    revalidateOnMount: true,
    initialData: {
      populars: [] as Article[],
    },
  })

  return (
    <section
      id="popular"
      className="container m-auto text-white mb-10 md:mb-20"
    >
      <div className="grid grid-cols-3 gap-8 p-4 md:p-20 bg-blue-300 mx-0 md:-mx-20 md:rounded-lg shadow-md">
        <div className="col-span-3 md:col-span-1">
          <div className="text-5xl mb-2">
            <FontAwesomeIcon icon={faStars} />
          </div>
          <p className="mb-4">Paling Populer di</p>
          <h2 className="mb-4 text-3xl font-semibold">
            {moment().format('MMMM YYYY')}
          </h2>
          <p className="opacity-75">
            Sepuluh konten Direktorat SMK yang paling sering dikunjungi di bulan
            ini.
          </p>
        </div>
        <div className="col-span-3 md:col-span-2">
          <ul className="grid grid-cols-1 md:grid-cols-2 md:grid-rows-5 md:grid-flow-col gap-8">
            {data.populars.length === 0 &&
              [...new Array(10)].map((_, index) => (
                <li className="mb-4 flex" key={index}>
                  <Skeleton className="w-10 h-10 rounded-full" />
                  <div className="flex-1 pl-4">
                    <Skeleton className="h-4 rounded" />
                    <Skeleton className="w-8/12 h-4 mt-3 rounded" />
                  </div>
                </li>
              ))}
            {data.populars.map((item, index) => (
              <li className="mb-4 flex" key={index}>
                <div className="count bg-blue-100 w-10 h-10 font-semibold flex justify-center items-center text-center rounded-full">
                  {index + 1}
                </div>
                <Link href={`/konten/${item.id}/${item.slug}`}>
                  <a className="block flex-1 ml-4 font-semibold">
                    {item.title}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default PopularSection
